import {Avatar, Flex, Layout} from "antd";
import "./style/NavBar.css"

export default function NavBar() {
    return (
        <Layout.Header className="Nav">
            <Flex  style={{width: "100%"}}>
                <Flex vertical align='center' justify='center'>
                    <Avatar src = "/avatar.png" className='Avatar' size={90}></Avatar>
                </Flex>
                <Flex vertical>
                    <div className="AvatarText">
                        <h3>GoAdvent</h3>
                    </div>
                </Flex>
            </Flex>
        </Layout.Header>
    )
}