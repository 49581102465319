import {Layout} from "antd";
import NavBar from "./components/NavBar";
import MainContent from "./components/MainContent";
import Footer from "./components/Footer";

function App() {
  return (
      <Layout>
          <NavBar />
          <MainContent />
          < Footer/>
      </Layout>
  );
}

export default App;
