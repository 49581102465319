import {Avatar, Layout} from 'antd';
import { FaInstagram, FaLinkedin, FaFacebook, FaTwitter } from 'react-icons/fa';

export default function Footer() {
    return (
        <Layout.Footer
            style={{
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <div>
                <Avatar src = "/logo-green.png" className='Avatar' size={90}></Avatar>
            </div>
            <div>
                GoAdvent ©{new Date().getFullYear()} Created by BZConsultant
            </div>
            <div style={{ marginTop: '20px' }}>
                <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" style={{ margin: '0 10px', color: '#344955' }}>
                    <FaInstagram size={20} />
                </a>
                <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer" style={{ margin: '0 10px', color: '#344955' }}>
                    <FaLinkedin size={20} />
                </a>
                <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" style={{ margin: '0 10px', color: '#344955' }}>
                    <FaFacebook size={20} />
                </a>
                <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer" style={{ margin: '0 10px', color: '#344955' }}>
                    <FaTwitter size={20} />
                </a>
            </div>
        </Layout.Footer>
    );
}
